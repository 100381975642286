<template>
  <div class="token">
    <h4 class="tokenTitle">
      <img width="55" :src="tokenId ? tokenURIImage : $getIconToken(seasonToken)" :alt="token.name.contract" />
      <span>{{ $t(token.label) }}</span>
    </h4>
    <div class="tokenInfoBlocks">
      <div class="tokenInfoBlock">
        <div
          v-if="
            tokenMetaData(blockchain.contracts, seasonToken.tokenAddress).interface === 'erc20' ||
            !BigNumber(balance).isZero()
          "
          class="blockItem"
        >
          <span class="heading"> {{ $t('appStatisticRate') }}: </span>
          <p class="rate value">
            {{ props.seasonToken.tokenMultiplier || 1 }} =
            {{ BigNumber(rate).integerValue(BigNumber.ROUND_FLOOR).toNumber() }}
            <img width="24" :src="tokens.seasonPoints" alt="" />
          </p>
        </div>
        <div class="blockItem">
          <span class="heading"> {{ $t('appStatisticOwnedToken') }}: </span>
          <span class="value"> {{ formatAmount(Math.floor(Number(balance))) }}</span>
        </div>
      </div>

      <div class="tokenInfoBlock">
        <div class="blockItem maxPoints">
          <span class="value"
            >{{ maxPoints }}
            <img width="24" :src="tokens.seasonPoints" alt="" />
          </span>
        </div>
        <div v-if="BigNumber(balance).isGreaterThan(0)" class="blockItem">
          <button class="btn-outline small w-button exchangeBtn" @click="burnCardClickHandler">
            {{ $t('appStatisticExchangeButton') }} <img width="10" src="/img/icons/arrowRight.svg" alt="arrowRight" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';

import { type TTokensConfigItem } from '~/utils/constants';
import tokens from '~/public/img/tokens';
import type { TSeasonConfigItem } from '~/types/season';

const { address } = useWeb3ModalAccount();
const { getContractReadOnly } = useAbiAccess();
const { blockchain } = useEnvs();

const props = defineProps<{
  token: TTokensConfigItem;
  rate: string | number;
  balance: string | number;
  maxPoints: string | BigNumber;
  burnCardClickHandler: () => void;
  tokenId?: number;
  seasonToken: TSeasonConfigItem;
}>();

const tokenURIImage = asyncComputed(async () => {
  const tokenContract = await getContractReadOnly('erc721', props.seasonToken.tokenAddress);

  const tokenUri = await tokenContract.tokenURI(props.tokenId);

  const image = await $fetch(tokenUri);

  return image.image_url;
});

onBeforeMount(() => {
  return { address: address.value };
});
</script>

<style lang="scss" scoped>
.token {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  border-radius: 15px;
  padding: 10px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #041a27 0%, #041a27 100%), #14232a;
}

.optionItem {
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 32px;
  font-weight: 400;
}

.tokenTitle {
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 110px;

  @media screen and (max-width: 479px) {
    font-size: 18px;

    span {
      font-size: 15px;
    }
  }
}

.tokenInfoBlocks {
  display: flex;
  gap: 10px;
  width: 100%;
}

.tokenInfoBlock {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    .maxPoints {
      font-weight: 700;
    }

    .exchangeBtn {
      display: flex;
      font-size: 14px;
      max-width: 100%;
      gap: 5px;
      padding: 10px;
    }
  }
  .heading {
    color: #8e979c;
    font-size: 12px;
    font-weight: 500;
    font-family: Grenze, sans-serif;
    display: flex;
    line-height: 1;
  }

  .value {
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    font-family: Grenze, sans-serif;
    line-height: 1;
    display: flex;
    align-items: center;

    @media screen and (max-width: 479px) {
      font-size: 20px;
    }
  }
}
</style>
