<template>
  <el-tooltip ref="refPopper" popper-class="customPopper" effect="dark" :show-after="200">
    <template #content>
      <div v-if="isTooltipVisible" ref="refContent" class="tokenTooltip" @mouseleave="handleMouseLeave">
        <h4 class="tooltipTitle">
          <img width="50" :src="tokens[token.key as Tokens]?.toString()" :alt="token.key" />
          {{ $t(tokensConfig[token.key].label) }}
        </h4>
        <div class="tooltipInfoBlocks">
          <div class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading"> {{ $t('appStatisticOwnedToken') }}: </span>
              <span class="value">{{ format(balance) }}</span>
            </div>
            <div class="blockItem">
              <span class="heading"> {{ $t('appStatisticRewardsEarned') }}: </span>
              <span class="value">{{ format(earnedBalance) }}</span>
            </div>
          </div>

          <div class="tooltipInfoBlock">
            <div class="blockItem">
              <span class="heading">{{ $t('storageMobileTotalPearlShare') }}:</span>
              <span class="value">{{ format(shared, 2) }}%</span>
            </div>
            <div
              v-if="token.isSellable || (!token.isDistributable && token.isBuybackEnabled)"
              class="blockItem"
              @click="handleClick"
            >
              <NuxtLink class="blockItem-stats-link" :href="`/dashboard?scrollToContainer=true`"
                >{{ $t('storageStatistics') }} <img src="/img/icons/arrowRight.svg" alt="arrowRight"
              /></NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </template>
    <storage-item
      :token-media="tokens[token.key as Tokens]?.toString()"
      :owned-value="format(balance)"
      :earned-value="format(earnedBalance)"
      @mouseenter="handleMouseEnter"
    />
  </el-tooltip>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { formatEther } from 'ethers';
import { Tokens, tokensConfig } from '~/utils/constants';
import type { ITokenModel } from '~/types/apiService';
import { useTokensReader } from '#imports';
import tokens from '~/public/img/tokens';

const store = useMainStore();

const isTooltipVisible = ref<boolean>(false);
const refPopper = ref();
const refContent = ref();

const handleClick = () => {
  store.currentToken = props.token.key;
  emit('close');
  isTooltipVisible.value = false;
  refPopper.value.hide();
};

const handleMouseEnter = () => {
  isTooltipVisible.value = true;
};

const handleMouseLeave = () => {
  isTooltipVisible.value = false;
};

// TODO: make it as reusable function
function format(value: string, decimals: number = 2): string {
  if (!value || value === '...') {
    return value;
  }
  return new BigNumber(value).toFormat(decimals);
}

const { address } = useWeb3ModalAccount();
const { availableTokens, totalStake, earned, staked } = useTokensReader();
const { getContractReadOnly } = useAbiAccess();
const emit = defineEmits(['close']);
const props = defineProps<{
  token: ITokenModel;
}>();
const balance = ref('0');
const earnedBalance = ref('0');
const shared = ref('0');
const { blockchain } = useEnvs();

async function minedBalance(): Promise<string> {
  if (!address.value) return '';

  const mineContract = await getContractReadOnly('mine', blockchain.contracts[props.token.key].addresses.mine || '');
  const mined = await mineContract.mined(address.value);

  return Number(BigNumber(formatEther(mined[0])).decimalPlaces(2)).toString();
}

watch(
  () => props.token.key,
  async () => {
    if (!address.value) return;

    const isLicenseToken = blockchain.contracts[props.token.key]?.addresses?.mine;
    const [tokenTotalStaked, tokenTotalEarned, tokenAvailableBalance, totalStaked] = await Promise.allSettled([
      props.token.isSellable ? staked(address.value, props.token.key) : 0,
      props.token.isSellable && !isLicenseToken ? earned(address.value, props.token.key) : 0,
      availableTokens(address.value, props.token.tokenContractAddress),
      totalStake(props.token.key)
    ]).then((results) => results.map((res) => (res.status === 'fulfilled' ? res.value : 0)));

    balance.value = new BigNumber(tokenAvailableBalance as string).toString();
    earnedBalance.value = !isLicenseToken ? new BigNumber(tokenTotalEarned).toString() : await minedBalance();
    shared.value = new BigNumber((totalStaked as string) || '0').isZero()
      ? '0'
      : new BigNumber(Number(tokenTotalStaked))
          .dividedBy(new BigNumber(totalStaked as string))
          .multipliedBy(100)
          .toFixed(4);
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.tokenTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tooltipTitle {
  display: flex;
  gap: 10px;
  color: #88e1ff;
  align-items: center;

  img {
    flex-shrink: 0;
  }
}

.tooltipInfoBlocks {
  display: flex;
  gap: 10px;
}

.tooltipInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #2f2f2f;
  background: linear-gradient(0deg, #0d242f 0%, #0d242f 100%), linear-gradient(0deg, #10364a 0%, #10364a 100%), #14232a;

  .blockItem {
    display: flex;
    flex-direction: column;

    &-stats-link {
      margin-top: auto;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-color: #fff;
      border-radius: 48px;
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 25px;

      img {
        height: 18px;
        width: 18px;
        margin-left: 8px;
      }
    }
  }
  .heading {
    color: #8e979c;
    font-size: 20px;
    font-family: Grenze, sans-serif;
    line-height: 40px;
    font-weight: 500;
    display: flex;
    align-items: center;

    img {
      flex-shrink: 0;
      margin-left: -8px;
    }
  }

  .value {
    color: #fff;
    font-family: Grenze, sans-serif;
    font-size: 32px;
    font-weight: 500;
  }
}
</style>

<!-- popper custom styles -->
<style>
.customPopper {
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px solid #2f2f2f !important;
  background: rgba(0, 0, 0, 0.75) !important;
}

.el-popper__arrow {
  display: none;
}
</style>
